/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import Service from 'components/cards/service';
import icon4 from 'assets/images/icons/one.png';
import icon5 from 'assets/images/icons/two.png';
import icon6 from 'assets/images/icons/three.png';
import icon7 from 'assets/images/icons/four.png';
import icon8 from 'assets/images/icons/five.png';
import icon9 from 'assets/images/icons/six.png';

const data = [
  {
    id: 1,
    icon: icon4,
    // moreLink: '#learn-more',
    title: 'Visit The Site',
    description: `Have a look at who we are and what we do.`,
  },
  {
    id: 2,
    icon: icon5,
    // moreLink: '#learn-more',
    title: 'Book A Chat',
    description: `We're happy to provide a complimentary initial consultation.`,
  },
  {
    id: 3,
    icon: icon6,
    // moreLink: '#learn-more',
    title: ' We\'ll Match',
    description: `Based on our conversation, we'll find the perfect tutor for you.`,
  },
  {
    id: 4,
    icon: icon7,
    // moreLink: '#learn-more',
    title: 'You Confirm',
    description: `After an introductory session, you can make your mind up about continuing.`,
  },
  {
    id: 5,
    icon: icon8,
    // moreLink: '#learn-more',
    title: 'You\'re All Set',
    description: `Once you're happy, the lesson plan will begin!`,
  },
  {
    id: 6,
    icon: icon9,
    // moreLink: '#learn-more',
    title: 'Begin Learning',
    description: `With our advice, you are free to choose how regularly you wish to have lessons and we do the rest!`,
  },
];

const OtherServices = () => {
  return (
    <Box as="section" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="How It Works"
          description="Our six step process will help us to pair you with the perfect tutor for you and your child!"
        />
        <Box sx={styles.contentWrapper}>
          {data?.map((item) => (
            <Service key={item.id} item={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};
export default OtherServices;

const styles = {
  section: {
    backgroundColor: '#F9FAFC',
    pt: [9, 9, 9, 11],
    pb: [9, 9, 9, 12, 12, 14],
  },
  heading: {
    mb: [6, null, null, 8, 9, null, 13],
    p: {
      maxWidth: 500,
      margin: '10px auto 0',
    },
  },
  contentWrapper: {
    gap: ['30px 30px', '30px 30px', '30px 30px', '80px 30px'],
    display: 'grid',
    justifyContent: ['center', 'center', 'center', 'unset'],
    gridTemplateColumns: [
      'repeat(1, 285px)',
      'repeat(1, 325px)',
      'repeat(1, 285px)',
      'repeat(3, 1fr)',
    ],
  },
};
