/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import { rgba } from 'polished';
import SectionHeading from 'components/section-heading';
import Service from 'components/cards/service';
import icon9 from 'assets/images/icons/service9.png';
import icon2 from 'assets/images/icons/service2.png';
import icon3 from 'assets/images/icons/service4.png';

const data = [
  {
    id: 1,
    icon: icon9,
    title: 'Subject Tutoring',
    description: `Our team of elite tutors allow
     students to learn in a stress-free environment, both face to face or online. 
     Students are encouraged to ask for help, and can truly learn at their own pace. `,
  },
  {
    id: 3,
    icon: icon2,
    title: 'Aptitude Tests',
    description: `Whether this is the TSA, UKCAT, LNAT, BMAT, or ELAT our tutors offer unsurpassable experience and expertise in preparing students for whatever the tests may throw at you.`,
  },
  {
    id: 4,
    icon: icon3,
    title: 'Interview Prep',
    description: ` No matter the subject, the interviews are designed to push you to adapt and defend your knowledge in the face of an expert in the field. CTG's tutors can provide invaluable  experience, and support you every step of the way.`,
  },
];

const Services = () => {
  return (
    <Box as="section" id="services" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="What We Offer"
          description=""
        />
        <Box sx={styles.contentWrapper}>
          {data?.map((item) => (
            <Service key={item.id} item={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Services;

const styles = {
  section: {
    backgroundColor: rgba('#FFF5ED', 0.5),
    pt: [11, 11, 11, 12, 12, 12, 14],
    pb: [7, 7, 7, 9, 9, 10, 11],
  },
  heading: {
    maxWidth: [null, null, null, 455, 660],
    mb: [6, null, null, 8, null, 9, 13],
  },
  contentWrapper: {
    gap: 30,
    display: 'grid',
    justifyContent: ['center', null, null, 'unset'],
    gridTemplateColumns: [
      'repeat(1, 285px)',
      'repeat(1, 325px)',
      'repeat(1, 285px)',
      'repeat(3, 1fr)',
    ],
  },
};
